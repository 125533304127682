.my-calendar-class {
    background-color: white;
    border: none;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    font-family: "MyFont", sans-serif;
    font-size: 16px;
}

.custom-datepicker {
    text-align: center;
    display: block;
    height: 45px;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;

    color: black;
    background-color: #edf3fa;
    background-clip: padding-box;
    border: 1px solid #d1e7ff;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
}

.custom-datepicker.self {
    color: black;
    background-color: #cad7e3;
    background-clip: padding-box;
    border: 1px solid #c2dbf1;
}

.custom-datepicker:focus {
    outline: none !important;
    border-color: #070707;
    outline: 0;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    transition-duration: 0.2s;
}