.menu-bar {
    height: 100vh;
    margin-top: 20vh;
    margin-right: 20px;
}

.menu-button {
    width: 240px;
    height: 60px;
    padding-top: 19px;
    padding-left: 30px;

    margin-top: 10px;

    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    color: #969696;
}

.menu-button:hover {
    background-color: #f3f2f2;
    color: #737373;
    transition-duration: 0.2s;
    border-radius: 6px;
}

.back-arrow-button {
    position: absolute;
    top: 180px;
    padding: 8px;
    margin-left: 10px;
    transition-duration: 0.2s;
}

.back-arrow-button:hover {
    margin-left: 7px;
    transform: scale(90%);
}

.menu-button-text {
    margin-left: 15px;
    font-weight: 700;
    font-size: 16px;
}

.menu-button-icon {
    position: relative;
    top: -2px;
}

.menu-button.active {
    color: #32386A;
    width: 240px;
    height: 60px;
    padding-top: 19px;
    padding-left: 30px;

    background: #EFF3FF;
    border-radius: 6px;
}