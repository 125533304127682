.selected {
    padding: 13px 47px;
    border-radius: 5px;
    font-family: 'myInter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    border: none;
    background: #EDDDE3;
    color: #000000;
    margin-right: 20px;
    transition-duration: 0.3s;
}

.select-year-btn {
    padding: 16px 47px;
    background-color: #F1F1F1;
    color: #B8B8B8;
    border-radius: 5px;
    font-family: 'myInter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    border: none;
    margin-right: 20px;
    transition-duration: 0.3s;
}

.selected:hover {
    cursor: pointer;
}

.select-year-btn:hover {
    background-color: #eaeaea;
    color: #9a9a9a;
    cursor: pointer;
}

.vacation-block {
    width: 400px;
}