.modal-form {
    font-family: "MyFont", sans-serif;
}

.modal-contract {
    font-family: "MyInter", sans-serif;
}

.modal-form-title {
    padding-top: 5px;
    padding-left: 10px;
    margin-bottom: -5px;
}

.modal-form-text {
    color: black;
    padding-top: 40px;
    padding-right: 40px;
    padding-left: 40px;
    padding-bottom: 20px;
    font-size: 18px;
    text-align: center;
}

/* Add this CSS to your component's style.css or your global styles */

.quantity-selector {
    display: flex;
    align-items: center;
}

.quantity-selector-button {
    background-color: #000000; /* Button background color */
    color: #fff; /* Button text color */
    border: none;
    padding: 5px 10px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.quantity-selector-button:hover {
    background-color: #1f1f1f; /* Hover background color */
}

/* Style the quantity input field */
.quantity-selector input[type="text"] {
    width: 40px;
    text-align: center;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 0 10px;
    padding: 5px;
}

/* Adjust the size and spacing as needed to fit your design */

.block-style {
    width: 125px;
    height: 125px;
    background: #f8f8f8;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;
    cursor: pointer;
}
