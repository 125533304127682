.thumbnail-block {
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;
}

.thumbnail-block:hover {
    background: #EFF3FF;
    box-shadow: 0px 0px 8px rgb(195, 203, 236);
    cursor: pointer;
}

.thumbnail-download-svg {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition-duration: 0.3s;
}

.thumbnail-download-svg:hover {
    transform: translateY(-3px);
}