.edit-place {
    border: 2px dashed #f1f1f1;
}

.bold-place {
    border: 2px solid #f1f1f1;
}

.bold-place:hover {
    border: 2px solid #3b3b3b;
}

.edit-place:hover {
    border: 2px dashed #96caee;
    color: #282828;
}

.delete-place {
    border: 2px dashed #f1f1f1;
}

.delete-place:hover {
    border: 2px dashed #f3cdcd;
    color: #282828;
}

.place-style {
    width: 300px;
    height: 125px;
    background: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;
}

.place-style-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    color: #dadada;
}