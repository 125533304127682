.checkbox-wrapper {
    margin-left: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox-wrapper > span {
    font-size: 14px;
    transition-duration: 0.3s;

}

.wrapper-active {
    margin-left: -2px;
    color: #000000;
    font-weight: bold;
}


.wrapper-disabled {
    margin-left: -6px;
    color: #919191;
}

.checkbox-wrapper-57 input[type="checkbox"] {
    visibility: hidden;
    display: none;
}

.checkbox-wrapper-57 *,
.checkbox-wrapper-57 ::after,
.checkbox-wrapper-57 ::before {
    box-sizing: border-box;
}

.checkbox-wrapper-57 .container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 20px;
    user-select: none;
}

/* Create a custom checkbox */
.checkbox-wrapper-57 .checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background: #eaeaea;
    border-radius: 5px;
    transition: all 0.4s;
    --spread: 7px;
}

/* When the checkbox is checked, add a blue background */
.checkbox-wrapper-57 .container input:checked ~ .checkmark {
    background: black;
    box-shadow: 0px 0px var(--spread) 0px #5B51D8, 0 0px var(--spread) 0px #833AB4, 0px 0px var(--spread) 0px #E1306C, 0 0 var(--spread) 0px #FD1D1D, 0px 0px var(--spread) 0px #F77737, 0 0px var(--spread) 0px #FCAF45, -1px 1px var(--spread) 0px #FFDC80;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-wrapper-57 .checkmark::after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-wrapper-57 .container input:checked ~ .checkmark::after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-wrapper-57 .container .checkmark::after {
    left: 11px;
    top: 6px;
    width: 8px;
    height: 14px;
    border: solid #f0f0f0;
    border-width: 0 0.1em 0.1em 0;
    transform: rotate(45deg);
}