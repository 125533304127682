.document-arrange-mode-fixed-bth {
    position: fixed;
    right: 10em;
    bottom: 30px;
}

.place-fixed-position {
    position: fixed;
    bottom: 20px;

}

.display-none {
    display: none;
}

.display-flex {
    display: flex;
    gap: 20px
}