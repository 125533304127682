header {
    position: relative;
    font-size: 28px;
    font-weight: 600;
    color: #333;
}

header::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 3px;
    width: 27px;
    border-radius: 8px;
    background-color: #4079f4;
}

.create-worker-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)), url("../background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    overflow-y: scroll;
}

.create-user-section {
    position: relative; /* set the position property to absolute */
    top: 150px;

    margin: 0 auto;
    /*transform: translate(-43%, -43%); !* use the transform property to move the element up by 50% of its own height *!*/
    padding: 50px 130px 50px;
    width: 1100px;
    min-height: 700px;

    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.35);
    border-radius: 5px;
}

.title {
    display: block;
    margin-bottom: 8px;
    font-size: 22px;
    font-weight: bold;
    color: #c4c4c4;
    transition-duration: 0.3s;
}

.title:hover {
    color: #9d9d9d;
}


.grey-btn-company-span {
    color: #A4A4A4;
    transition-duration: 0.3s;
}


.file-input-company {
    height: 38px;
}

.extra-space {
    width: 100%;
    height: 200px;

}

.dark-btn-company {
    height: 50px;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    border: none;

    color: #ffffff;
    background: #0B0B0B;

    border-radius: 4px;

    z-index: 1;
    moz-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

.show-password-label {
    position: relative;
    margin-left: 0.3rem;
    top: 2px;
    font-size: 16px;
    color: #333;
}

.show-password-checkbox input[type="checkbox"] {
    /* Hide the default checkbox */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    position: relative;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}


.show-password-checkbox input[type="checkbox"]:checked {
    /* Style for checked checkbox */
    background-color: #666df9;
}

