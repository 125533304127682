.button-submit,
.button-reject {
    min-height: 30px;
    min-width: 80px;
    padding-top: 10px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 10px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    font-weight: 400;
    background: #1e1e1e;
    border-radius: 4px;
    z-index: 0;
    color: #ffffff;
    white-space: nowrap;
    box-sizing: content-box;
    transition: background .25s ease-in-out, color .25s ease-in-out;
}

.button-reject {
    margin-left: 10px;
    color: #333333;
    background: #E8E8E8;
}

.button-reject:hover {
    background: #dadada;
    color: #0e0e0e;
}

.button-reject > span {
    color: inherit;
    transition-duration: 0.3s;
}

.form-control.input-field:focus {
    border-color: #000000;
    box-shadow: none;
}

.form-control:focus {
    border-color: #000000;
    box-shadow: none;
}

.input-field-label {
    font-size: 18px;

    transition-duration: 0.3s;
    margin-left: 1px;
}

.input-field {
    height: 45px;
}

.notifications-empty-content,
.worker-empty-content,
.empty-content {
    display: flex;
    font-size: 20px;
    font-weight: 300;
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Align items vertically */
    align-items: center; /* Center items horizontally */
    height: 700px; /* Set a specific height to center vertically */
}

.empty-content > div {
    display: block;
}

.empty-content p {
    text-align: center; /* Center text horizontally within the container */
}

.worker-empty-content {
    position: relative;
    left: -80px;
    top: -30px;
}

.notifications-empty-content {
    padding-top: 50px;
    height: 280px; /* Set a specific height to center vertically */
}

.button-block {
    width: 130px;
    height: 80px;
    background: #f8f8f8;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;
    cursor: pointer;
}

.add-button-style {
    width: 52px;
    height: 40px;
    border-radius: 5px;
    border: none;
    font-weight: bold;
    color: #656565;
    background-color: #e8e8e8;
    transition: 0.25s ease-in-out;
}

.add-button-style:hover {
    background-color: #dcdcdc;
    transition: 0.25s ease-in-out;
}

.remove-button-style {
    width: 50px;
    height: 38px;
    border-radius: 5px;
    border: none;
    font-weight: bold;
    margin-left: -10px;
    color: #545454;
    background-color: #e8e8e8;
    transition: 0.25s ease-in-out;
}

.remove-button-style:hover {
    background-color: #dcdcdc;
    transition: 0.25s ease-in-out;
}

.invalid {
    color: #ff8e8e;
    transition-duration: 0.3s;
}

.invalid:hover {
    color: #050505;
    transition-duration: 0.3s;
}

.download-button {
    min-height: 30px;
    min-width: 80px;
    padding-top: 10px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 10px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    font-weight: 400;
    background: #c2c2c2;
    border-radius: 4px;
    z-index: 0;
    color: #ffffff;
    white-space: nowrap;
    box-sizing: content-box;
    transition: background .5s ease-in-out, color .3s ease-in-out;
}

.cursor_pointer {
    cursor: pointer;
}