.certification-text {
    margin-top: 2px;
    margin-left: 14px
}

.certification-text > h4 {
    font-weight: 700;
    font-size: 17px;
    color: #2F2F2F
}

.certification-text > div > h5 {
    font-weight: 700;
    font-size: 17px;
    color: #9f9f9f;
    transition-duration: .2s
}

.certification-text > div > h5:hover {
    color: #7e7d7d;
}

.certification-text > div > p {
    font-weight: 700;
    font-size: 17px;
    color: #000
}