.titleData > div {
    font-weight: 700;
    font-size: 32px;

    cursor: text;
}

.titleData > p {
    font-weight: 700;
    font-size: 17px;
    cursor: text;
}

.titleData > div > span {
    position: relative;
    top: -7px;
    width: 100px;
    height: 50px;
    background: #E4FFE3;
    border-radius: 5px;

    font-weight: 600;
    font-size: 14px;
    /* identical to box height */

    margin-left: 15px;
    padding: 10px 15px 10px 15px;

    color: #000000;
    cursor: text;
}

.worker-subtitle {
    display: block;
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: bold;
    color: #9a9a9a;
    transition-duration: 0.3s;
    cursor: text;
    margin-left: 2px;
}

.worker-subtitle:hover {
    color: #7e7e7e;
}

.personalData {
    margin-top: 25px;
}

.worker-card {
    padding-top: 12px;
    padding-bottom: 7px;
    padding-left: 15px;
    padding-right: 8px;
    background: #F7F7F7;
    border: solid 1px #efefef;
    border-radius: 8px;
    margin-top: 9px;
    margin-bottom: 8px;
    margin-right: 5px;
    font-size: 18px;
    font-weight: 700;
    transition-duration: 0.3s;
}

.worker-card:hover {
    box-shadow: 0px 3px 10px rgba(61, 61, 61, 0.15);
}

.worker-card > span {
    position: relative;
    top: -3px;
}

.worker-card > label {
    width: 100%;
    position: relative;
    font-family: MyFont, sans-serif;
    font-weight: 400;
    font-size: 14px;
    top: -7px;
    color: #818181;
}

.pesel {
    width: 300px;
}

.pesel > span > span {
    position: absolute;
    top: -12px;
    left: 185px;
}

.bills {
    margin-top: 25px;
}

.relatives {
    margin-top: 25px;
}

.contacts {
    margin-top: 25px;
}

.last-updated {
    color: #969696;
    margin-top: 30px;
    margin-left: 25px;
    font-size: 16px;
}

.insurance {
    display: flex;
    flex-wrap: wrap;

}

.insurance > div > p {
    font-weight: 700;
}

.rachunek-card > span > span {
    position: absolute;
    top: -12px;
    left: 455px;
}

.certificate,
.contract {
    padding: 20px 15px 20px 25px;
    width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0 1px 10px rgba(26, 26, 26, 0.15);
    border-radius: 10px;
    margin-bottom: 15px;
    transition-duration: 0.3s;
    font-weight: bold;
}

.certificate:hover,
.contract:hover {
    box-shadow: 0 4px 21px rgba(0, 0, 0, 0.10);
}

.certificate > h4,
.contract > h4 {
    font-weight: 700;
    font-size: 20px;

    color: #2F2F2F;
}

.contract > h4 > span {
    font-weight: 500;
    font-size: 16px;

    color: #989898;
}

.certificate > div > h5,
.contract > div > h5 {
    margin-top: 20px;
    font-weight: 700;
    font-size: 18px;

    color: #7e7d7d;
    transition-duration: 0.3s;
}

.certificate > div > h5:hover,
.contract > div > h5:hover {
    color: #7e7d7d;
}

.certificate > div > p,
.contract > div > p {
    width: 320px;
    font-weight: 700;
    font-size: 18px;
}

.certificate > div > div,
.contract > div > div {
    width: 320px;
    font-weight: 700;
    font-size: 18px;
}


.vacation {
    padding: 20px 15px 200px 25px;
    width: 100%;
    height: 215px;
    background: #FFFFFF;
    box-shadow: 0 1px 10px rgba(26, 26, 26, 0.15);
    border-radius: 10px;
    margin-bottom: 15px;
    transition-duration: 0.3s;
}

.vacation:hover {
    box-shadow: 0 4px 21px rgba(0, 0, 0, 0.10);
}

.vacation > h4 {
    font-weight: 700;
    font-size: 20px;
    color: #2F2F2F;
    margin-bottom: 10px;
}

.vacation-text {
    margin-top: 2px;
    margin-left: 8px;
}

.vacation-text > h4 {
    font-weight: 700;
    font-size: 17px;

    color: #2F2F2F;
}

.vacation-text > div > h5 {
    font-weight: 700;
    font-size: 17px;

    color: #9f9f9f;
    transition-duration: 0.3s;
}


.vacation-text > div > h5:hover {
    color: #7e7d7d;
}

.vacation-text > div > p {
    font-weight: 700;
    font-size: 17px;
    color: #000000;
}


.worker-page > div {
    cursor: pointer;
}

.worker-page > div > h3 {
    cursor: auto;
}

.worker-page-second > div > div > p {
    cursor: pointer;
}

.expiring-document > p {
    color: #FFB038;
    transition-duration: 0.2s;
}

.expiring-document > p:hover {
    color: #b07e2e;
}

.no-valid-document > p {
    color: #E77273;
    transition-duration: 0.2s;
}

.no-valid-document > p:hover {
    color: #b7494a;
}

.worker-page-title {
    font-weight: 700;
    font-size: 24px;
}

.worker-page {
    position: relative;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.05);
    z-index: 100;
}

.worker-container {
    position: absolute;
    border-radius: 5px;
    width: 1200px;
    margin-top: -25px;
    padding-top: 50px;
    padding-right: 40px;

    z-index: 101;
}

.editing-mode {
    background-color: white;
    /* Adjust other styles for editing mode as desired */
}


.contract-edit {
    padding: 20px 15px 20px 25px;
    width: 100%;
    height: 215px;
    background: #f6f6f6;
    box-shadow: 0 4px 21px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin-bottom: 15px;
    transition-duration: 0.3s;
}

.contract-edit > h4 {
    font-weight: 700;
    font-size: 20px;

    color: #dedede;
}

.contract-edit > div > h5 {
    margin-top: 20px;
    font-weight: 700;
    font-size: 18px;

    color: #dedede;
    transition-duration: 0.3s;
}

.contract-edit > div > p {
    width: 320px;
    font-weight: 700;
    font-size: 18px;

    color: #dedede;
}


.vacation-text-edit {
    margin-top: 2px;
    margin-left: 8px;
}

.vacation-text-edit > h4 {
    font-weight: 700;
    font-size: 17px;

    color: #dedede;
}

.vacation-text-edit > div > h5 {
    font-weight: 700;
    font-size: 17px;

    color: #dedede;
    transition-duration: 0.3s;
}

.vacation-text-edit > div > p {
    font-weight: 700;
    font-size: 17px;
    color: #dedede;
}


.vacation-edit {
    padding: 20px 15px 200px 25px;
    width: 100%;
    height: 215px;
    background: #f6f6f6;
    box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    transition-duration: 0.3s;
}

.vacation-edit > h4 {
    font-weight: 700;
    font-size: 20px;
    color: #c9c9c9;
    margin-bottom: 10px;
}

.edit-worker-button {
    width: 100px;
}

.worker-content {
    padding-left: 65px;
    margin-top: 45px;
    margin-bottom: 100px;
}