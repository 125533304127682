.button-add-company {
    position: absolute;
    height: 70px;
    bottom: 0;
    margin-top: 10px;
    border-radius: 3px;
    padding-top: 30px;
    font-size: 18px;
    text-align: center;

    background: #fafafa;
    color: #a9a9a9;
    cursor: pointer;
    transition-duration: 0.3s;
}

.button-add-company:hover {
    box-shadow: 0 0 13px rgb(195, 203, 236);
    color: #737373;
}

.create-company-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)),
    url("../background-sand.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.create-company-container > div {
    background-color: white;
    width: 100px;
}

.create-company-section {
    position: absolute; /* set the position property to absolute */
    top: 50%; /* set the top property to 50% */

    transform: translate(-43%, -43%); /* use the transform property to move the element up by 50% of its own height */
    padding: 40px;
    width: 680px;
    height: 450px;

    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.35);
    border-radius: 5px;

}
