.company-reset-button {
    margin-right: 15px;
    width: 90px;
    height: 90px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    text-decoration: none;
    color: #626262;
    background-color: #f5f5f5;
    border-radius: 6px;
    transition-duration: 0.3s;
}

.company-reset-button:hover {
    background-color: #f3f2f2;
    color: #949494;
}

.company-reset-button.active-reset-button {
    height: 84px;
    background: #EFF3FF;
    color: #32386A;
}

.company-reset-button.active-reset-button:hover {
    background: #e5ebff;
}

.companies-horizontal-block {
    align-items: center;
    height: 100px;
    display: flex;
}

.companies-horizontal-block .vertical-slider {
    align-items: center;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
}

.worker-notification-button {
    margin-right: 12px;
    min-width: 120px; /* Adjust the minimum width as needed */
    max-width: 200px;
    padding-right: 25px;
    padding-left: 25px;
    height: 65px;
    /* Removed height property */

    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    color: #626262;
    background-color: #f5f5f5;
    border-radius: 5px;
    transition-duration: 0.3s;

    white-space: nowrap; /* Prevent text from wrapping */

    /* Ensure box-sizing is set to content-box */
    box-sizing: content-box;
}


.worker-notification-button:hover {
    background-color: #f3f2f2;
    color: #949494;
}

.worker-notification-button.active {
    height: 60px;
    background: #EFF3FF;
    color: #32386A;
}

.worker-notification-button.active:hover {
    background: #e5ebff;
}
