.square-with-text:hover {
    box-shadow: 0px 0px 13px rgb(195, 203, 236);
    color: #282828;
    cursor: pointer;
}

.delete-square-with-text:hover {
    box-shadow: 0px 0px 5px rgb(255, 124, 124);
    color: #282828;
    cursor: pointer;
}