.worker-vacation-block {
    display: flex;
    align-items: center;
    border: 1px solid #eaeaea;
    border-radius: 7px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 14px;
    padding-bottom: 10px;
    width: 100%;
    position: relative;
}

.fired-indicator {
    background: #ff5454;
    padding: 10px;
    border-radius: 6px;
    margin-right: 12px;
}

.worker-vacation-presentation {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    margin-right: 8px;
    align-items: center;
    justify-content: center;
    height: 40px;
    min-width: 200px;
    padding: 6px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 3px;
    margin-bottom: 3px;
}

.delegations .worker-vacation-presentation {
    background-color: #fff;
    color: black;
}

.empty-indicator {
    width: 5px;
    border-radius: 6px;
    margin-right: 12px;
}

.worker-index {
    margin-right: 20px;
}

.worker-name {
    font-size: 16px;
    font-weight: 500;
    margin-right: 25px;
    white-space: nowrap;
    cursor: pointer;
}

.worker-vacations {
    display: flex; /* Add display flex to make them inline */
    align-items: center; /* Align elements vertically in the middle */
    overflow-x: scroll;
    margin-right: 22px;
}

/* Add this CSS class to style the "show all" vacation presentations */
.show-all {
    display: flex;
    align-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
}

.vacations-scrollable {
    display: flex;
    flex-wrap: nowrap;
}

.action-button {
    margin-left: auto; /* Align to the right corner */
    text-align: center;
    cursor: pointer;
}

.delegations {
    border-top-width: 0;
    border-top-style: solid;
    border-top-color: #eaeaea;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #eaeaea;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: #eaeaea;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #eaeaea;
    align-items: center;
    padding: 10px 30px 10px 23px;
    display: flex;
    background-color: #050505;
    width: 100%;
    height: 100%;
    color: #FFFFFF;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
}

.delegation-title {
    text-align: center;
    color: white;
    font-weight: 500;
    margin-right: 25px;
}

.scrollable-container {
    overflow-x: auto;
    white-space: nowrap;
}