.block {
    padding: 20px 15px 10px 25px;
    width: 100%;
    height: 90px;
    background: #FFFFFF;
    box-shadow: 0 1px 10px rgba(26, 26, 26, 0.15);
    border-radius: 10px;
    margin-bottom: 15px;
    flex: 1 0 calc(50% - 50px); /* set a width for each block and subtract the margin */
    transition-duration: 0.3s;
}

.block:not(:last-child) {
    margin-right: 15px; /* Add a margin to the right side for all elements except the last child */
}

.block:hover {
    box-shadow: 0 4px 21px rgba(0, 0, 0, 0.10);
}

.block > h4 {
    font-weight: 700;
    font-size: 20px;

    color: #2F2F2F;
    margin-bottom: 8px;
}

.block-edit {
    padding-top: 20px;
    padding-right: 0px;
    padding-left: 20px;
    padding-bottom: 10px;
    width: 100%;
    height: 90px;
    background: #FFFFFF;
    box-shadow: 0 1px 10px rgba(26, 26, 26, 0.15);
    border-radius: 10px;
    margin-bottom: 10px;
    flex: 1 0 calc(50% - 20px); /* set a width for each block and subtract the margin */
    transition-duration: 0.3s;
}

.ekuz {
    width: 400px;
    height: auto;
    flex: 0 0 calc(70% - 15px); /* set a width for each block and subtract the margin */
}

.cfr-date-end {
    width: 300px;
    background: #f3f3f3;
    box-shadow: none;
    flex: 0 0 calc(50% - 15px); /* set a width for each block and subtract the margin */
}
.badanie {
    flex: none;
}

.block-edit:not(:last-child) {
    margin-right: 15px; /* Add a margin to the right side for all elements except the last child */
}

.block-edit > h4 {
    font-weight: 700;
    font-size: 20px;

    color: #2F2F2F;
    margin-bottom: 8px;
}

.cfr-date-end > h4 {
    color: #ababab;
}

.note {
    width: 100%;
    padding-top: 20px;
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 10px;
    min-height: 100px;
    background: #FFFFFF;
    box-shadow: 0 1px 10px rgba(26, 26, 26, 0.15);;
    border-radius: 10px;
    margin-bottom: 15px;
    transition-duration: 0.3s;
}

.note span {
    font-weight: 700;
    font-size: 18px;

    color: #9b9b9b;
}

.note:hover {
    box-shadow: 0 4px 21px rgba(0, 0, 0, 0.10);
}

.note > h4 {
    font-weight: 700;
    font-size: 20px;
    color: #2F2F2F;
    margin-bottom: 12px;
}

.note > div {
    position: relative;
    width: 100%;

    font-weight: 400;
    font-size: 18px;
    margin-bottom: 8px;
}

.block-edit > p > span,
.block > p > span {
    font-weight: 700;
    font-size: 18px;

    color: #9a9a9a;
    margin-right: 8px;
    transition-duration: 0.3s;
}

.block-edit > p > span:hover,
.block > p > span:hover {
    color: #696868;
    margin-right: 10px;
}

.cfr-date-end > p > span {
    color: #b7b7b7;
}
.cfr-date-end > p > span:hover {
    color: #b7b7b7;
}
.block-edit > p,
.block > p {
    font-weight: 700;
    font-size: 17px;

    color: #C5C5C5;
    margin-right: 10px;
}


