.dl {
    --background: #fff;
    --primary: #000000;
    --border-radius: 4px;
    --square-size: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 12px;
    width: 115px;
}

.dl .dl-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--border-radius);
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.dl .dl-box .dl-button {
    width: 45px;
    height: 45px;
    position: relative;
    display: flex;
    justify-content: center;
    background: var(--primary);
    cursor: pointer;
    transition: width 0.2s ease-in 0.4s, height 0.2s ease-in 0.4s, background 0.2s ease-in 0.4s;
}

.dl .dl-box .dl-button .arrow {
    position: absolute;
    top: 16px;
    width: 2px;
    height: 13px;
    background: var(--background);
    border-radius: var(--border-radius);
    transition: background 0.2s ease-in 0.4s;
}

.dl .dl-box .dl-button .arrow:before, .dl .dl-box .dl-button .arrow:after {
    content: "";
    display: block;
    width: 2px;
    height: 8px;
    background: var(--background);
    position: absolute;
    bottom: 0px;
    transform-origin: 50% 100%;
    border-radius: var(--border-radius);
    transition: background 0.2s ease-in 0.4s;
}

.dl .dl-box .dl-button .arrow:before {
    transform: rotate(-45deg) translateX(25%);
}

.dl .dl-box .dl-button .arrow:after {
    transform: rotate(45deg) translateX(-25%);
}

.dl .dl-box .dl-button .basket {
    position: absolute;
    width: 40px;
    height: 20px;
    bottom: 13px;
    border-radius: var(--border-radius);
}

.dl .dl-box .dl-button .basket:before, .dl .dl-box .dl-button .basket:after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 0;
    width: 0;
    display: block;
    opacity: var(--opacity, 0);
    transition: opacity 0.15s linear;
    border-bottom: solid 2px var(--primary);
    box-sizing: border-box;
}

.dl .dl-box .dl-button .basket:before {
    left: calc(40px / 2);
    border-right: solid 2px var(--primary);
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.dl .dl-box .dl-button .basket:after {
    right: calc(40px / 2);
    border-left: solid 2px var(--primary);
    border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.dl .dl-box .dl-button .basket .basket-top:before, .dl .dl-box .dl-button .basket .basket-top:after {
    content: "";
    position: absolute;
    top: 0;
    height: 0;
    width: 0;
    display: block;
    border-top: solid 2px var(--primary);
}

.dl .dl-box .dl-button .basket .basket-top:before {
    left: 2px;
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.dl .dl-box .dl-button .basket .basket-top:after {
    right: 2px;
    border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.dl .dl-status {
    margin-left: 0;
    flex-basis: 0;
    width: 0;
    transition: all 0.2s ease-out 0.2s;
    clip-path: inset(0 var(--square-size) 0 0);
}

.dl .dl-status .text {
    text-align: left;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.3);
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 23px;
}

.dl .dl-status .text.text-number {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
}

.dl.run .dl-box .dl-button {
    width: var(--square-size);
    height: var(--square-size);
    background: var(--background);
    transition: width 0.2s ease-out 0.3s, height 0.2s ease-out 0.3s, background 0.2s ease-out 0.3s;
}

.dl.run .dl-box .dl-button .arrow {
    background: var(--primary);
    transition: background 0.3s ease-in;
    animation: arrow-main 0.75s ease-in forwards infinite;
}

.dl.run .dl-box .dl-button .arrow:before, .dl.run .dl-box .dl-button .arrow:after {
    transition: background 0.3s ease-in;
    background: var(--primary);
}

.dl.run .dl-box .dl-button .arrow:before {
    animation: arrow-left 0.75s ease-in forwards infinite;
}

.dl.run .dl-box .dl-button .arrow:after {
    animation: arrow-right 0.75s ease-in forwards infinite;
}

.dl.run .dl-box .dl-button .basket:before, .dl.run .dl-box .dl-button .basket:after {
    --opacity: 1;
    transition: opacity 0s linear 0.3s;
    animation: btn-border 0.3s forwards ease-in normal 0.5s;
}

.dl.run .dl-box .dl-button .basket .basket-top:before, .dl.run .dl-box .dl-button .basket .basket-top:after {
    animation: btn-border-top 0.3s forwards ease-in normal 0.8s;
}

.dl.run .dl-status {
    width: 100px;
    clip-path: inset(0 0 0 0);
    transition: all 0.3s ease-in 0.7s;
}

.dl.run .dl-status .text.text-number::after {
    animation: status-animation 1.5s ease 1.5s forwards;
}

.dl.done .dl-box .dl-button .arrow {
    animation-play-state: paused;
}

.dl.done .dl-box .dl-button .arrow:before {
    animation-play-state: paused;
}

.dl.done .dl-box .dl-button .arrow:after {
    animation-play-state: paused;
}

@keyframes arrow-main {
    0% {
        transform: translateY(0);
    }
    30% {
        transform: translateY(-5px);
    }
    80% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes arrow-left {
    0% {
        transform: rotate(-45deg) translateX(25%);
    }
    30% {
        transform: rotate(-55deg) translateX(25%);
    }
    80% {
        transform: rotate(-35deg) translateX(25%);
    }
    100% {
        transform: rotate(-45deg) translateX(25%);
    }
}

@keyframes arrow-right {
    0% {
        transform: rotate(45deg) translateX(-25%);
    }
    30% {
        transform: rotate(55deg) translateX(-25%);
    }
    80% {
        transform: rotate(35deg) translateX(-25%);
    }
    100% {
        transform: rotate(45deg) translateX(-25%);
    }
}

@keyframes btn-border {
    0% {
        width: 0;
        height: 0;
    }
    50% {
        width: calc(40px / 2);
        height: 0;
    }
    100% {
        width: calc(40px / 2);
        height: 20px;
    }
}

@keyframes btn-border-top {
    0% {
        width: 0px;
    }
    100% {
        width: 10px;
    }
}
