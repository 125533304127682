.login-page {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../background-2.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center
}

.logo-title {
    position: absolute; /* set the position property to absolute */
    top: 49%; /* set the top property to 50% */
    transform: /* use the transform property to move the element up by 50% of its own height */ translateY(-60%)
}

.logo-title span {
    position: relative;
    left: 110px;
    top: 66px;
    font-size: 36px;
    font-weight: 700;
    color: #fff
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: /* Non-prefixed version, currently
                                         supported by Chrome, Edge, Opera and Firefox */ none
}

.square {
    background-color: #fff;
    border-radius: 5%;
    height: 80px;
    width: 80px
}

.square p {
    color: #151515;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    height: 80px;
    width: 80px;
    font-size: 32px;
    font-weight: 700
}

.card-title h3 {
    font-size: 30px;
    line-height: 1.2;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px
}

.card-title {
    margin-bottom: 40px
}

.title-login-page {
    font-size: 28px;
    font-weight: 500
}

.subtitle-login-page {
    font-size: 20px;
    font-weight: 300;
    color: #545454
}

.center-block {
    background-color: #FFF; /* Semi-transparent white background */
    padding: 80px;
    width: 970px;
    height: 610px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center
}

.login-section {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 785px;
    height: 535px;
    background: #FFF;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 40px;
}

.login-form {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.input_component {
    text-align: left;
    padding-top: 10px;
    width: 100%;
    min-width: 230px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #bdbdbd;
    transition-duration: 0.3s;
}

.login__component__input {
    min-width: 300px;
}

.input_component:focus {
    outline: none !important;
    border: 1px solid #030303;
}

.login-form h5 {
    font-size: 24px;
    font-weight: bold;
    font-family: MyInter, sans-serif;
}


.login-section Form {
    padding-top: 55px;
    padding-left: 25px
}

.login-section button {
    margin-top: 5px;
    margin-left: 10px
}

.btn-hover.color {
    background-image: linear-gradient(90deg, #1b1b1f 0%, #0f1717 100%)
}

.alert {
    margin-top: 3px;
    font-size: 14px;
    background-color: #fff
}

.alert h3 {
    font-size: 14px
}

.group {
    position: relative;
    margin-left: 57px;
    margin-bottom: 45px
}

.group button {
    margin-top: 8px;
    margin-left: 0
}

.login-link {
    text-decoration: none;
    color: #b7b7b7;
    transition-duration: .3s
}

.login-link:hover {
    text-decoration: none;
    color: #626161
}

.auth-input {
    font-size: 16px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 265px;
    border: none;
    border-bottom: 1px solid #9f9f9f
}

.auth-input:focus {
    outline: none
}

.version-marker {
    position: fixed;
    text-align: center;
    color: #c5c5c5;
    bottom: 10px;
    font-size: 12px;
    left: 30px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.square-with-text.spinning {
    animation: spin 2s linear infinite
}


@keyframes spin {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(360deg)
    }
}

.group label {
    color: #999;
    font-size: 16px;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 5px;
    transition: .2s ease all;
    -moz-transition: .2s ease all;
    -webkit-transition: .2s ease all
}

.checkbox-2 {
    font-weight: 700
}

.checkbox label {
    font-size: 15px;
    position: absolute;
    pointer-events: none;
    left: 3px;
    top: 6.3px;
    transition: .2s ease all;
    -moz-transition: .2s ease all;
    -webkit-transition: .2s ease all
}

/* active state */
.auth-input:focus ~ label, .auth-input:valid ~ label {
    top: -23px;
    font-size: 14px;
    color: #15171c
}

.checkbox {
    cursor: pointer
}

.checkbox input {
    height: 18px;
    width: 18px;
    border-radius: 2px
}

.checkbox label {
    margin-left: 25px;
    margin-top: -8px
}

/* BOTTOM BARS ================================= */
.bar {
    position: relative;
    display: block;
    width: 265px
}

.bar:before, .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #15171c;
    transition: .2s ease all;
    -moz-transition: .2s ease all;
    -webkit-transition: .2s ease all
}

.bar:before {
    left: 50%
}

.bar:after {
    right: 50%
}

.auth-input:focus ~ .bar:before, .auth-input:focus ~ .bar:after {
    width: 50%
}

.highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: .4
}

