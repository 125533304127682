.visa-worker,
.personal-details-worker,
.passport-worker {
    display: flex;
    flex-direction: row;
}

.visa-date,
.birthday-place,
.passport-date {
    width: 100%;
    margin-left: 10px;
}

.visa-document,
.pass-document {
    width: 340px;
}
