.seen, .unseen {
    font-family: 'MyFont', sans-serif;
    width: 100%;
    min-height: 85px;
    margin-bottom: 10px;
    border: solid 1px #f1f1f1;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition-duration: 0.3s;
}

.seen > div {
    background: #f6f6f6;
    box-shadow: none;
}

.unseen > div {
    background: #ffffff;
}

.seen:hover, .unseen:hover {
    box-shadow: 0px 0px 8px rgb(195, 203, 236);
    cursor: pointer;
}

.notification-message {
    margin-top: 18px;
    margin-right: 10px;
}

.notification-duration {
    margin-top: 33px;
    font-weight: 500;
    font-size: 16px;

    text-align: center;
    margin-left: -15px;
    color: #000000;
}

.notification-message-title {
    font-weight: 500;
    font-size: 20px;
    color: #000000;
}

.notification-time-sent {
    margin-top: 33px;
    font-weight: 400;
    font-size: 16px;

    text-align: center;

    color: #000000;
}

.notification-message-subtitle a {
    text-decoration: none;
    color: #000000;
    transition-duration: 0.3s;
}

.notification-message-subtitle a:hover {
    text-decoration: none;
    color: #8d8d8d;
    cursor: pointer;
}

.seen .notification-time-sent,
.seen .notification-duration,
.seen .notification-message-title,
.seen .notification-message-subtitle a {
    color: #c9c9c9;
}

.seen .notification-message-subtitle a:hover {
    text-decoration: none;
    color: #9a9a9a;
    cursor: pointer;
}

.greyscale-svg {
    filter: grayscale(100%);
    opacity: 0.7;
}