.companies-vertical-block {
    padding-top: 15px;
    padding-bottom: 35px;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    min-width: 235px;
    max-width: 330px;
    min-height: 65vh;
    max-height: 70vh;
    border-radius: 10px;
    box-shadow: 0 1px 10px rgba(26, 26, 26, 0.13);
    overflow: auto;
}

.transmission-modal-container {
    padding: 30px;
    min-height: 400px;
    overflow: auto;
}

.transmission-modal-container h5 {
    text-align: center;
    margin-bottom: 20px;
    font-weight: normal;
}

.company-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 3px;

    padding-top: 6px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
}

.company-block {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgb(234, 234, 234);
    padding-top: 15px;
    padding-right: 20px;
    padding-left: 15px;
    padding-bottom: 15px;
    transition-duration: 0.3s;
}

.company-block:hover {
    box-shadow: 0px 0px 13px rgb(195, 203, 236);
    color: #282828;
    cursor: pointer;
}

.company-block-disabled {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    color: #000000;
    padding-top: 15px;
    padding-right: 20px;
    padding-left: 15px;
    padding-bottom: 15px;
    transition-duration: 0.3s;
}

.company-block-disabled:hover {
    box-shadow: 0px 0px 13px rgb(195, 203, 236);
    color: #282828;
}

.company-logo-disabled {
    margin-right: 18px;
}

.company-logo-block {
    margin-right: 18px;
}

.company-logo-container {
    margin-right: 12px;
}

.company-text-container {
    flex: 1;
}

.company-text-block {
    flex: 1;
}

.company-text-block p {
    font-size: 20px;
    font-weight: 400;
    margin: 0;
}

.company-text-container p {
    font-size: 15.5px;
    font-weight: bold;
    margin: 0;
}

.company-logo {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    box-shadow: 0px 0px 5px rgba(173, 173, 173, 0.1);
}

.company-logo-disabled {
    width: 50px;
    height: 50px;
    filter: grayscale(1); /* Partial grayscale effect */
}

.dummy-company-logo {
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #444444;
    color: #FFFFFF;
    border-radius: 4px;
}

.disabled-dummy-logo {
    width: 50px;
    height: 50px;
    background: #dadada;
    border-radius: 4px;
}


/* */
.company-notification-button {
    margin-right: 15px;
    max-width: 260px;
    height: 90px;

    padding-left: 18px;
    padding-right: 15px;
    display: inline-block;
    text-decoration: none;
    border-radius: 6px;
    background-color: #f6f6f6;
    color: #626262;
    transition-duration: 0.3s;
}

.company-notification-button:hover {
    background-color: #f3f2f2;
    color: #949494;
    transition-duration: 0.2s;
}

.company-notification-button.active {
    height: 84px;
    color: #32386A;
    background: #EFF3FF;
}

.company-notification-button.active:hover {
    background: #e5ebff;
}

.company-menu-button {
    width: 100%;
    height: auto;

    padding-top: 4px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;

    margin-bottom: 6px;

    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    color: #a1a1a1;
}

.company-display {
    max-width: 100%;

    padding-top: 4px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;

    margin-bottom: 6px;

    display: inline-block;
    font-weight: bold;
    font-size: 20px;
    color: #a1a1a1;
}

.company-menu-button:hover {
    background-color: #f3f2f2;
    color: #949494;
    transition-duration: 0.2s;
    border-radius: 6px;
}

.company-menu-button-text {
    margin-left: 15px;

    font-weight: 700;
    font-size: 16px;
}

.company-display.active,
.company-menu-button.active {
    color: #32386A;
    background: #EFF3FF;
    border-radius: 6px;
}

.context-menu {
    position: relative;
    z-index: 9999;
    width: 100px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 5px;
    transition-duration: 0.3s;
}

/* Style the individual menu items */
.context-menu-item {
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s;

}

.context-menu-item:hover {
    background-color: #f5f5f5;
}

/* Add a little space between menu items */
.context-menu-item + .context-menu-item {
    margin-top: 5px;
}

/* iPads, Tablets and Mobile devices */
@media only screen and (max-width: 1024px) {
    /* CSS styles for iPads and tablets */
    .companies-vertical-block {
        max-width: 100px;
        min-width: 80px;
    }

    .company-text-container {
        display: none
    }

    .company-menu-button {
        padding-left: 0px;
        padding-right: 0px;
    }

    .company-logo-container {
        margin-right: 0px;
    }
}

/* Desktops, large screens */
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    /* CSS styles for desktops and large screens */
    .companies-vertical-block {
        max-width: 240px;
        min-width: 150px;
        padding-left: 4px;
        padding-right: 4px;
    }

    .company-menu-button {
        padding-left: 5px;
        padding-right: 3px;
    }

    .company-logo-container {
        margin-right: 13px;
    }
}

