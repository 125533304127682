body {
    font-family: 'MyFont', sans-serif;
}

.search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}

.message_block {
    margin-left: 10px;
    position: absolute;
    height: 100%;
    padding-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 10px;
    min-width: 100px; /* Add this to make sure it takes up the full width */
    color: #32386A;
    background: #EFF3FF;
    text-align: center;
    display: flex; /* Use flexbox */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */

    border-radius: 5px;
    font-weight: bold;
    font-size: 15px;

    left: 0;
}


.search-block {
    margin-left: 35px;
    display: flex;
    align-items: center;
    flex: 1 1;
    justify-content: center;
    gap: 10px;
}

.search-input {
    text-align: left;
    min-width: 300px;
    padding-top: 6px;
    padding-right: 13px;
    padding-left: 18px;
    padding-bottom: 6px;
    border-radius: 5px;
    border: 1px solid #bdbdbd;
    transition-duration: 0.3s;
}

.search-input:focus {
    outline: none !important;
    border: 1px solid #030303;
}

.totalElements {
    text-align: center;
    min-width: 50px;
    height: 39px;

    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    padding-bottom: 5px;
    /*margin-left: 10px;*/
    background: #f8f8f8;
    border-radius: 4px;
    font-weight: 400;
    color: #6b6b6b;
    font-size: 16px;

    transition-duration: 0.4s;
}

.add-worker-button {
    position: relative;

    width: 110px;
    right: 0;
    height: 39px;

    margin-left: 10px;
    background: #f8f8f8;
    border-radius: 4px;
    border: none;
    font-family: 'MyFont', sans-serif;
    font-weight: 400;
    font-size: 18px;

    transition-duration: 0.4s;
}

.add-worker-button:hover {
    box-shadow: 0px 0px 5px rgb(195, 203, 236);
}

.search label input::placeholder {
    font-weight: 400;
    font-size: 14px;

    color: #979797;
}

.input-container {
    position: relative;
}

.input-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
}

.search-checkbox-label {
    position: relative;
    margin-left: 0.4rem;
    top: 4px;

    font-weight: 400;
    font-size: 14px;

    color: #7a7a7a;
}

.search-checkbox input[type="checkbox"] {
    /* Hide the default checkbox */
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 25px;
    top: -1.6px;
    height: 25px;
    border: 1px solid #cbcbcb;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.search-checkbox input[type="checkbox"]:checked {
    /* Style for checked checkbox */
    background-color: #666df9;
}

.workers {
    border-collapse: separate;
    padding-left: 10px;
    border-spacing: 0 5px; /* Change the second value to adjust the distance */
}

.users {
    border-collapse: separate;
    padding-left: 10px;
    border-spacing: 0 8px; /* Change the second value to adjust the distance */
}

.workers thead tr {
    position: relative;
    left: -1px;
}

.workers thead tr th {
    border: none;
    text-align: center;
}

.users thead tr th {
    border: none;
    text-align: center;
}

.workers tbody td {
    text-align: center;
    height: 65px;
    padding-top: 9px;
    border: none;
}

.users tbody td {
    text-align: center;
    min-height: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: none;
}

.workers tbody tr {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.users tbody tr {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.parsel > span {
    position: relative;
    top: 13px;
    font-weight: 400;
    font-size: 16px;
    height: 18px;

    color: #90969D;
}

tbody > tr > td > div {
    color: #1a1a1a;

    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
}

tbody > tr > td > span {
    color: #90969D;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
}

tbody > tr > td > span:hover {
    opacity: 0.8;
    transition-duration: 0.2s;
}

tbody > tr > td > div:hover {
    opacity: 0.8;
    transition-duration: 0.2s;
}

.fired-block {
    filter: gray(1);
}

.id {
    margin-left: 8px;
}

.id > span {
    font-weight: 300;
    font-size: 17px;
    color: #3f3f3f;
    cursor: text;
}

.actions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}

.actions > div {
    position: relative;
}


.aneks-color-text {
    color: #3e8aff;
}

.expired-color-text {
    color: #bd0404;
}

.default-color-text {
    color: #000000;
}