@font-face {
    font-family: 'MyFont';
    src: url(./assets/fonts/CirceFont/CRC25.ttf) format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'MyFont';
    src: url(./assets/fonts/CirceFont/CRC35.ttf) format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'MyFont';
    src: url(./assets/fonts/CirceFont/CRC55.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'MyFont';
    src: url(./assets/fonts/CirceFont/Circe-Bold.ttf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'MyFont';
    src: url(./assets/fonts/CirceFont/Circe-ExtraBold.ttf) format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'myInter';
    src: url(./assets/fonts/Inter/Inter-Bold.ttf) format('truetype');
    font-weight: 700;
}

/* ROBOTO AND INTER*/

body {
    font-family: MyFont, sans-serif;
}