.notification-page-title {
    font-family: 'MyFont', sans-serif;
    font-weight: 500;
    font-size: 28px;

    margin-top: 5px;
    margin-bottom: 15px;
    color: #000000;
}

.notification-page-title > span {
    position: relative;
    top: -4px;
    width: 110px;
    height: 50px;
    background: #E4FFE3;
    border-radius: 5px;

    font-weight: 500;
    font-size: 15px;

    margin-left: 10px;
    padding: 9px 20px 9px 20px;

    color: #000000;
    cursor: text;
}
