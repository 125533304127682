/* Style the checkbox input */
.toggle-switch-container input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

/* Style the label for the toggle switch */
.toggle-switch-container label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 30px;
    background: #e3e3e3;
    display: block;
    border-radius: 10px;
    position: relative;
}

/* Style the thumb of the toggle switch */
.toggle-switch-container label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.2s;
}

/* Change background color when the checkbox is checked */
.toggle-switch-container input:checked + label {
    background: #000000;
}

/* Slide the thumb to the right when the checkbox is checked */
.toggle-switch-container input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

/* Enlarge the thumb when the label is clicked */
.toggle-switch-container label:active:after {
    width: 25px;
}

/* Center the toggle switch in the viewport */
.toggle-switch-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
