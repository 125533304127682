li {
    list-style: none;
}

.navigation {
    margin: 22px auto 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 25px;
    background: #ffffff;
    box-shadow: 0 1px 10px rgba(26, 26, 26, 0.15);
    border-radius: 12px;

    font-family: 'MyFont', sans-serif;
}

.nav-link {
    padding-top: 22px;
    font-size: 16px;
    color: #565656;
    text-decoration: none;
    font-weight: 400;
}

.dropdown-link {
    padding-top: 10px;
    font-size: 16px;
    color: #737373;
    text-decoration: none;
    font-weight: 400;
}

.menu {
    display: flex;
    gap: 1em;
}

.menu li {
    padding: 0 23px;
}

/* DROPDOWN MENU */
.services {
    position: relative;
}

.dropdown {
    margin-top: 8px;
    background-color: rgb(255, 255, 255);
    padding: 10px 10px;
    position: absolute;
    display: none;
    border-radius: 5%;

    right: -13px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

}

.dropdown li + li {
    margin-top: 10px;
}

.dropdown li {
    width: 6em;
    text-align: center;
    padding: 5px 10px 15px;
}

.services:hover .dropdown {
    display: block;
}

.circle-profile {
    margin-top: 9px;
    margin-bottom: -7px;
    background-color: #f55555;
    border-radius: 7px;
    cursor: pointer;
}

.circle-inner {
    padding-left: 1px;
    letter-spacing: 0.05em;
    color: #ffffff;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    height: 47px;
    width: 47px;
    font-size: 13px;
    font-weight: bold;
}

.logo-nav {
    font-family: 'myInter', sans-serif;
    position: relative; /* set the position property to absolute */
    text-decoration: none;
    transform: translateY(0%); /* use the transform property to move the element up by 50% of its own height */
}

.logo-title-nav {
    margin-top: 14px;
    font-family: 'myInter', sans-serif;
    color: #000000;
    font-size: 20px;
    font-weight: 500;
}

.square-logo {
    background-color: #000000;
    border-radius: 6px;
    height: 50px;
    width: 50px;
    font-family: MyInter, sans-serif;
}

.square-logo p {
    color: #f8f8f8;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    height: 50px;
    width: 50px;
    font-size: 18px;
    font-weight: bold;
}

.square-logo.nav-logo {
    height: 55px;
    width: 55px;
}
.square-logo.nav-logo p {
    height: 55px;
    width: 55px;
}
.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome, Edge, Opera and Firefox */
}
